import Button from '@/components/UI/Button';
import { FormField, Text, Textarea } from '@/components/UI/FormFields';
import type { TaskType } from 'model/Task.model';
import type { FC } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import type { StepRenderProps } from '../AddTask.model';
import { AddTaskFormTypes } from '../AddTask.model';
import styles from '../AddTask.module.scss';

export const taskTypeOptions: Array<{ value: TaskType; label: string }> = [
  { value: 'other' as TaskType, label: 'Other' },
  { value: 'measurement' as TaskType, label: 'Measurement' },
  { value: 'sample' as TaskType, label: 'Sample' },
  { value: 'observation' as TaskType, label: 'Observation' },
  { value: 'dosing' as TaskType, label: 'Dosing' },
];

const AddTaskOverviewForm: FC<StepRenderProps> = ({ onSubmit, onCancel, submitButtonText, cancelButtonText }) => {
  const { handleSubmit: useFormHandleSubmit, control } = useFormContext();

  return (
    <form onSubmit={useFormHandleSubmit(onSubmit)} className={styles['step-content']}>
      <div>
        <div className="flex pv3">
          <div className="w-60 ph3" data-test-component="TaskInformationStep" data-test-element="task-title-container">
            <Text name={`${AddTaskFormTypes.overview}.title`} label="Title" maxLength={50} required />
          </div>
          <div className="w-40 ph3" data-test-component="TaskInformationStep" data-test-element="task-type-container">
            <FormField name="type" label="Task type">
              <Controller
                name={`${AddTaskFormTypes.overview}.type`}
                defaultValue={taskTypeOptions[0].value}
                control={control}
                rules={{ required: true }}
                render={({ value, onChange }: ControllerRenderProps) => (
                  <Select
                    isDisabled={true}
                    options={taskTypeOptions}
                    defaultValue={taskTypeOptions.find((option) => option.value === value)}
                    onChange={(option) => onChange(option?.value)}
                    className="ui__select f6"
                    classNamePrefix="ui__select"
                  />
                )}
              />
            </FormField>
          </div>
        </div>
        <div
          className="ph3 pb3"
          data-test-component="TaskInformationStep"
          data-test-element="task-description-container"
        >
          <Textarea
            name={`${AddTaskFormTypes.overview}.description`}
            label="Description"
            maxLength={255}
            className="w-100"
            style={{ maxWidth: 'none', resize: 'vertical' }}
          />
        </div>
      </div>
      <div className="pa3 bt b--moon-gray">
        <Button submit testKey="taskSpecStepFormSubmit">
          {submitButtonText}
        </Button>
        <Button plain className="ml2" onClick={onCancel}>
          {cancelButtonText}
        </Button>
      </div>
    </form>
  );
};

export default AddTaskOverviewForm;
